import { get, post } from './index'

// 获取底部联系方式
export const getContactInfo = (p) => {
  return get('/api/company/select', p)
}

// 查询项目分组
export const findProgram = (p) => {
  return get('/api/projectGroup/selectAll', p)
}
