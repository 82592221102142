<template>
  <div v-show="showBackTop">
    <!-- <a href="#" class="goTop">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      回到顶部
    </a> -->
    <!-- <a href="#" class="backTop">
      <img src="../../public/img/home/goTop.png" alt="" />
    </a> -->

    <div class="box" @click="backTop">
      <i class="iconfont icon-huojianxianxing"></i>
      <span>顶部</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    showBackTop: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      let scrollY = window.pageYOffset
      if (scrollY > 700) {
        this.$emit('isShowBackTop', true)
      } else {
        this.$emit('isShowBackTop', false)
      }
    },
    backTop() {
      var timer = setInterval(function () {
        var currentPosition =
          document.documentElement.scrollTop || document.body.scrollTop
        currentPosition -= 100
        if (currentPosition > 0) {
          window.scrollTo(0, currentPosition)
        } else {
          window.scrollTo(0, 0)
          clearInterval(timer)
        }
      }, 12)
    }
  }
}
</script>
<style lang="less" scoped>
.box {
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  position: fixed;
  z-index: 9;
  bottom: 40px;
  right: 40px;
  z-index: 9;
  transition: all 0.3s linear;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(51, 51, 51, 0.1);
  backdrop-filter: blur(6px);
  transition: all 0.5s linear;
  color: #999;
  &:hover {
    cursor: pointer;
    background-color: #999;
    color: #000;
  }
  i {
    font-size: 36px;
    margin-bottom: 10px;
  }
  span {
    font-size: 16px;
    font-weight: 700;
  }
}

.box:hover {
  border: 0;
  cursor: pointer;
  background-color: #eee;
}
// .backTop {
//   background: transparent;
//   position: fixed;
//   bottom: 100px;
//   right: 60px;
//   z-index: 9;
//   box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
//   transition: all 0.3s ease;
// }
// .goTop {
//   position: fixed;
//   bottom: 150px;
//   right: 200px;
//   transform: translate(-50%, -50%);
//   color: #1670f0;
//   padding: 10px 30px;
//   font-size: 30px;
//   letter-spacing: 2px;
//   text-transform: uppercase;
//   text-decoration: none;
//   background: rgba(51, 51, 51, 0.1);
//   box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
//   overflow: hidden;
// }

// .goTop > span:nth-child(1) {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 2px;
//   background: linear-gradient(to right, #0c002b, #1779ff);
//   animation: animate1 2s linear infinite;
// }
// @keyframes animate1 {
//   0% {
//     transform: translateX(-100%);
//   }
//   100% {
//     transform: translateX(100%);
//   }
// }
// .goTop > span:nth-child(2) {
//   position: absolute;
//   top: 0;
//   right: 0;
//   width: 2px;
//   height: 100%;
//   background: linear-gradient(to bottom, #0c002b, #1779ff);
//   animation: animate2 2s linear infinite;
//   animation-delay: 1s;
// }
// @keyframes animate2 {
//   0% {
//     transform: translateY(-100%);
//   }
//   100% {
//     transform: translateY(100%);
//   }
// }
// .goTop > span:nth-child(3) {
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   height: 2px;
//   background: linear-gradient(to left, #0c002b, #1779ff);
//   animation: animate3 2s linear infinite;
// }
// @keyframes animate3 {
//   0% {
//     transform: translateX(100%);
//   }
//   100% {
//     transform: translateX(-100%);
//   }
// }
// .goTop > span:nth-child(4) {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 2px;
//   height: 100%;
//   background: linear-gradient(to top, #0c002b, #1779ff);
//   animation: animate4 2s linear infinite;
//   animation-delay: 1s;
// }
// @keyframes animate4 {
//   0% {
//     transform: translateY(100%);
//   }
//   100% {
//     transform: translateY(-100%);
//   }
// }
</style>
