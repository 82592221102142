<template>
  <div>
    <el-container>
      <el-header :class="showBg ? 'bg' : ''">
        <Heads @isShowBg="isShowBg" />
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
      <Foot />
      <BackTop @isShowBackTop="isShowBackTop" :showBackTop="showBackTop" />
    </el-container>
  </div>
</template>

<script>
import Heads from '../components/heads1.vue'
import Foot from '../components/foot.vue'
import BackTop from '../components/backTop.vue'
export default {
  components: {
    Heads,
    Foot,
    BackTop
  },
  data() {
    return { showBg: false, showBackTop: false }
  },
  methods: {
    isShowBg(flag) {
      this.showBg = flag
    },
    isShowBackTop(flag) {
      this.showBackTop = flag
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-header {
  background-color: transparent !important;
  transition: all 0.3s linear;
}
/deep/.el-header.bg {
  // background: #005792 !important;
  background-color: rgba(51, 51, 51, 0.8) !important;
  backdrop-filter: blur(6px);
}

.el-main::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}
.el-main {
  overflow: hidden;
}
.el-header {
  width: 100%;
  // height: 88px;
  position: fixed;
  top: 0;
  z-index: 9;
}

/* 布局样式 */
.el-header,
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #ffffff;
  color: #333;
  text-align: center;
  padding: 0;
  /* line-height: 160px; */
}
.el-footer {
  padding: 0;
  color: #333;
  text-align: center;
  line-height: 60px;
  padding: 0;
}
.el-header {
  height: 88px !important;
}
.el-main {
  text-align: center;
  padding: 0;
}
.el-footer {
  width: 1200px;
  margin: auto;
}

.bannerBox {
  width: 100%;
  height: 563px;
  background-color: #005792;
  position: relative;
}
/* banner区域的导航栏样式 */
.bannerBox > .item {
  width: 930px;
  height: 158px;
  position: absolute;
  bottom: -43px;
  left: 50%;
  margin-left: -465px;
  box-sizing: border-box;
}
/* banner上方 */
.bannerBox > .item > .itemTop {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.bannerBox > .item > .itemTop > span {
  color: #ffffff;
  font-weight: 400;
  font-size: 40px;
}
.bannerBox > .item > .itemTop > .el-menu {
  width: 550px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
}

.bannerBox > .item > .itemTop > .el-menu > li {
  padding: 0px;
}
.bannerBox > .item > .itemTop > .el-menu > li:hover {
  background-color: #005792 !important;
  text-shadow: 0px 0px 5px;
  transition: all linear 200ms;
}
/* banner下方 */
.bannerBox > .item > .itemBottom {
  width: 930px;
  height: 86px;
  background-color: #ffffff;
  border-radius: 35px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 0px 3px 3px rgb(51, 51, 51, 0.5);
}
.bannerBox > .item > .itemBottom > div {
  position: relative;
  bottom: 10px;
}
.bannerBox > .item > .itemBottom > .bo1 {
  width: 224px;
  height: 40px;
  line-height: 40px;
  text-align: start;
}
.bannerBox > .item > .itemBottom > .bo1 > a > h3 {
  height: 32px;
  color: #02548c;
  font-weight: 400;
  font-size: 24px;
  margin: 0px;
}
.bannerBox > .item > .itemBottom > .bo1 > span {
  color: #c2c6cc;
  font-weight: 400;
  font-size: 14px;
  position: absolute;
  bottom: -20px;
}

.bannerBox > .item > .itemBottom > .bo2 {
  width: 485px;
  height: 62px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bannerBox > .item > .itemBottom > .bo2 > div {
  width: 96px;
  height: 32px;
  line-height: 32px;
  padding-left: 46px;
  border-left: 1px solid skyblue;
  text-align: start;
  position: relative;
}
.bannerBox > .item > .itemBottom > .bo2 > div > p {
  color: #484848;
  margin: 0px;
}
.bannerBox > .item > .itemBottom > .bo2 > div > span {
  width: 96px;
  color: #c2c6cc;
  position: absolute;
  left: 46px;
  bottom: -20px;
}
.bannerBox > .item > .itemBottom > .bo3 {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: #00bbf0;
  bottom: 5px;
}
/* 内容区域 */
.contentBox1 {
  width: 1200px;
  background-color: #ffffff;
  padding-top: 143px;
  margin: auto;
}
.contentBox1 > .contentBox1-1 {
  width: 100%;
  display: flex;
  width: 100%;
  text-align: start;
}
.contentBox1 > .contentBox1-1 > .imgBox {
  width: 496px;
  height: 360px;
  border-radius: 35px;
  background-color: #999;
}
.contentBox1 > .contentBox1-1 > .textBox {
  width: 640px;
  margin-left: 100px;
}
.contentBox1 > .contentBox1-1 > .textBox > h2 {
  color: #333333;
  font-size: 48px;
  margin: 30px 0 60px;
}
.contentBox1 > .contentBox1-1 > .textBox > div {
  line-height: 48px;
  color: #808080;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
}
.contentBox1 > .contentBox1-1 > .textBox > div > .title {
  color: #bbbbbb;
}
.contentBox1 > .contentBox1-1 > .textBox > p {
  line-height: 30px;
  color: #bdbdbd;
  font-size: 18px;
}

.contentBox1 > .contentBox1-2 {
  width: 100%;
  overflow: hidden;
}
.contentBox1 > .contentBox1-2 > h2 {
  text-align: start;
  font-size: 36px;
  font-weight: 400;
  margin: 100px 0;
  position: relative;
}
.contentBox1 > .contentBox1-2 > h2::after {
  content: 'CLASSIFICATION';
  display: block;
  width: 328px;
  height: 48px;
  font-size: 36px;
  font-weight: 400;
  color: rgba(128, 128, 128, 0.1);
  position: absolute;
  top: -18px;
}
.contentBox1 > .contentBox1-2 > .exhibitionBox {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.contentBox1 > .contentBox1-2 > .exhibitionBox > .imgBox {
  width: 842px;
  height: 476px;
  background: #999;
  border-radius: 35px;
}
.contentBox1 > .contentBox1-2 > .exhibitionBox > .textBox {
  margin-right: 45px;
}
.contentBox1 > .contentBox1-2 > .exhibitionBox > .textBox .el-collapse {
  width: 378px;
  height: 476px;
  border: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
::v-deep
  .contentBox1
  > .contentBox1-2
  > .exhibitionBox
  > .textBox
  > .el-collapse
  > .el-collapse-item {
  width: 100%;
  height: 210px;
  text-align: start;
  padding: 5px;
  border-radius: 20px;
}
::v-deep .exhibitionBoxTextItem {
  position: relative;
  border-radius: 20px;
  /* transition: all linear 1s; */
}
::v-deep .exhibitionBoxTextItem::after {
  content: '';
  display: block;
  width: 2px;
  height: 154px;
  background-color: #00bbf0;
  position: absolute;
  left: 0px;
  top: 37px;
}
/* ::v-deep .exhibitionBoxTextItem .el-collapse-item{
  background-color: rgba(51, 51, 51, 0.02);
} */
::v-deep .exhibitionBoxTextItem .el-collapse-item__header {
  background-color: rgba(51, 51, 51, 0.02);
  border-radius: 35px 35px 0px 0px;
}
::v-deep .exhibitionBoxTextItem .el-collapse-item__wrap {
  background-color: rgba(51, 51, 51, 0.02);
  border-radius: 0px 0px 35px 35px;
}

::v-deep
  .contentBox1
  > .contentBox1-2
  > .exhibitionBox
  > .textBox
  .el-collapse-item
  .el-collapse-item__header {
  border-bottom: 0px;
  text-align: start;
  padding: 10px;
}
::v-deep
  .contentBox1
  > .contentBox1-2
  > .exhibitionBox
  > .textBox
  .el-collapse-item
  .el-collapse-item__header
  > h3 {
  width: 100%;
  font-size: 24px;
  border-bottom: 1px solid #c4dee7;
  margin-left: 30px;
}
::v-deep
  .contentBox1
  > .contentBox1-2
  > .exhibitionBox
  > .textBox
  .el-collapse-item
  .el-collapse-item__wrap {
  border: 0px;
}
::v-deep
  .contentBox1
  > .contentBox1-2
  > .exhibitionBox
  > .textBox
  .el-collapse-item
  .el-collapse-item__wrap
  .el-collapse-item__content
  > p {
  font-size: 16px;
  color: #666666;
  margin-left: 30px;
}
/* 第三块内容 */
.contentBox2 {
  width: 100%;
  height: 656px;
  background-color: #eff0f2;
  padding: 100px 0px 100px;
  margin-top: 100px;
}
.contentBox2 > div {
  width: 1200px;
  height: 446;
  margin: auto;
}
.contentBox2 > div > .textBox {
  text-align: end;
  margin-bottom: 140px;
  position: relative;
}
.contentBox2 > div > .textBox::after {
  content: '';
  display: block;
  width: 150px;
  height: 2px;
  background-color: rgba(72, 72, 72, 0.4);
  position: absolute;
  right: 0px;
  bottom: -40px;
}
.contentBox2 > div > .textBox > h2 {
  font-size: 36px;
}
.contentBox2 > div > .textBox > h2 > span {
  color: #00bbf0;
}
.contentBox2 > div > .imgBox {
  height: 300px;
  text-align: end;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contentBox2 > div > .imgBox > div {
  width: 280px;
  height: 240px;
  background: rgba(153, 153, 153, 0.3);
  border-radius: 10px;
  position: relative;
  box-sizing: border-box;
}
::v-deep .contentBox2 > div > .imgBox > div:hover {
  color: #ffffff;
  transform: translate(0px, -30px);
  box-shadow: 0px 3px 3px rgb(51, 51, 51, 0.5);
  transition: all linear 300ms;
}
::v-deep .contentBox2 > div > .imgBox > div:hover > p {
  color: #ffffff;
}
.contentBox2 > div > .imgBox > div > p {
  text-align: start;
  font-weight: 600;
  font-size: 18px;
  color: #333333;
  padding-left: 32px;
  margin: 0px;
}
.contentBox2 > div > .imgBox > div > div {
  width: 280px;
  padding: 0 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 32px;
}

.contentBox2 > div > .imgBox > div > div > a {
  color: #f4f4f4;
}
.contentBox2 > div > .imgBox > div > div > a :hover {
  color: #ffffff;
  text-shadow: 0px 0px 3px #ffffff;
}

/* 第四块内容 */
.contentBox3 {
  background: #ffffff;
}
.contentBox3 > div > .textBox {
  text-align: start;
}

.contentBox3 > div > .textBox::after {
  left: 0px;
}
.contentBox3 > div > .imgBox > div > div > a {
  color: #999999;
}
.contentBox3 > div > .imgBox > div > div > a :hover {
  color: #ffffff;
  text-shadow: 0px 0px 3px #ffffff;
}

/* 第五块 */
.contentBox4 {
  width: 1200px;
  /* height: 484px; */
  text-align: start;
  margin: auto;
  margin-bottom: 80px;
}
.contentBox4 > h2 {
  font-weight: 600;
  font-size: 36px;
  color: #484848;
}
.contentBox4 > p {
  font-weight: 400;
  font-size: 18px;
  color: #9a9a9a;
  margin: 20px 0px;
}
.contentBox4 > .itemBox {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: center;
}
.contentBox4 > .itemBox > div {
  width: 176px;
  height: 160px;
  background: #eff0f2;
  border-radius: 8px;
  border: 2px solid transparent;
  margin: 25px 0;
  position: relative;
}
.contentBox4 > .itemBox > div:hover {
  border: 2px solid #666;
}
.contentBox4 > .itemBox > div > h6 {
  text-align: center;
  font-size: 32px;
  margin: 0px;
  position: absolute;
  left: 72px;
  top: 50px;
}
.contentBox4 > .itemBox > div > p {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  color: #484848;
  position: absolute;
  top: 80px;
}

/* 图片 */
.contentimgBox {
  width: 100%;
  height: 484px;

  background: #005792;
  overflow: hidden;
  text-align: center;
}
.contentimgBox > img {
  height: 484px;
}
.contentBox6 {
  width: 1200;
  margin: 100px auto;
}
.contentBox6 > img {
  width: 1200px;
}
.contentBox6 > h2 {
  text-align: center;
  font-weight: 400;
  font-size: 48px;
  color: #000000;
}

.contentBox6 > .imgBox {
  width: 1200px;
  height: 300px;
  /* display: flex; */
  /* flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: baseline; */
  margin: auto;
}
.contentBox6 > .imgBox > img {
  width: 280px;
  height: 88px;
}

.contentBox7 {
  width: 100%;
  overflow-y: auto;
}
.contentBox7 > .hBox > h2 {
  height: 362px;
  text-align: center;
  padding-top: 48px;
  color: #484848;
  background: #eff0f2;
  font-size: 36px;
  font-weight: 700;
}
.contentBox7 > .contBox {
  width: 1200px;
  background: #eff0f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  position: relative;
  top: -200px;
}
.contentBox7 > .contBox > div {
  width: 382px;
  height: 400px;
  background: #e0e2e6;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 50px 0px;
  position: relative;
}
.contentBox7 > .contBox > div > p {
  text-align: center;
}
.contentBox7 > .contBox > div > .text1 {
  font-size: 18px;
  font-weight: 600;
  color: #484848;
}
.contentBox7 > .contBox > div > .text2 {
  font-size: 16px;
  font-weight: 400;
  color: #9a9a9a;
}
.contentBox7 > .contBox > div > .text3,
.contentBox7 > .contBox > div > text4 {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  margin-top: 64px;
}
.contentBox7 > .contBox > div > .text5 {
  font-size: 24px;
  font-weight: 400;
  color: #333333;
  margin-top: 84px;
}
.contentBox7 > .contBox > div > .img1 {
  width: 100px;
  border-radius: 50px;
  position: absolute;
  top: -50px;
  left: 141px;
}
.contentBox7 > .contBox > div > div {
  width: 200px;
  height: 200px;
  background: #9a9a9a;
  margin: auto;
}
body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
</style>
