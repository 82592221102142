<template>
  <div class="footer">
    <footer class="foot">
      <div class="w">
        <div class="infos">
          <div class="title">
            <h1>{{ contactLists.companyName }}</h1>
            <p>{{ contactLists.companyTitle }}</p>
          </div>

          <div class="all">
            <div class="list one" v-for="item in lists" :key="item.id">
              <h3>{{ item.name }}</h3>
              <div
                class="li"
                style="cursor: pointer"
                v-for="obj in item.data"
                :key="obj.id"
                @click="handleClick(obj.path)"
              >
                {{ obj.msg }}
              </div>
            </div>

            <!-- <div class="list two">
              <h3>项目中心</h3>
              <div class="programsbox">
                <div
                  class="programs li"
                  v-for="obj in programLists"
                  :key="obj.id"
                  @click="handleToProgram(obj)"
                >
                  {{ obj.name }}
                </div>
              </div>
            </div> -->

            <div class="list three">
              <h3>联系方式</h3>
              <div class="li">联系电话: {{ contactLists.companyTel }}</div>
              <div class="li">联系邮箱: {{ contactLists.companyEmail }}</div>
              <div class="li">联系地址: {{ contactLists.companyAddress }}</div>
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="copyright">
          <span>© 2023 thecreation.design | All rights raserved</span>
          <a href="https://beian.miit.gov.cn/" target="_blank">
            备案号: 蜀ICP备16005413号
          </a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { getContactInfo, findProgram } from '../api/foot'
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      contactLists: {
        contactLists: '',
        companyTitle: '',
        companyTel: '',
        companyEmail: '',
        companyAddress: ''
      },

      programLists: [],

      lists: [
        {
          id: 101,
          name: '关于恒速',
          data: [
            { id: 1000, msg: '定制服务', path: '/customService' },
            { id: 1001, msg: '关于我们', path: '/about' },
            { id: 1002, msg: '人才招聘', path: '/hire' }
            // { id: 1003, msg: "联系我们", path: "/contact" },
          ]
        }
      ]
    }
  },
  created() {
    this._initData()
  },
  methods: {
    handleClick(path) {
      this.$router.push(path)
    },
    handleToProgram(obj) {
      console.log(obj)
      this.$router.push(`/parentPage/${obj.id}`)
    },
    _initData() {
      getContactInfo().then((res) => {
        if (res.status === 200) {
          this.contactLists = res.data ? res.data : {}
        }
      })
      findProgram().then((res) => {
        if (res.status === 200) {
          this.programLists = res.data ? res.data : []
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.foot {
  text-align: left;
  width: 100%;
  background: #333333;
  backdrop-filter: blur(10px);
  padding: 72px 0 50px;
  box-sizing: border-box;

  .infos {
    display: flex;
    padding-bottom: 80px;
    box-sizing: border-box;
    .title {
      margin-right: 111px;
      width: 325px;

      h1 {
        font-family: 'OPPOSans-M';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: #ffffff;
        margin-bottom: 16px;
        margin-top: 0;
      }
      p {
        font-family: 'OPPOSans-L';
        width: 325px;
        height: 140px;
        overflow: hidden;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        color: #9a9a9a;
      }
    }
    .all {
      flex: 1;
      display: flex;
      overflow: hidden;
      .list {
        h3 {
          font-family: 'OPPOSans-L';
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          color: #ffffff;
          margin-bottom: 20px;
          margin-top: 8px;
        }
        .li {
          font-family: 'OPPOSans-L';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: rgba(255, 255, 255, 0.65);
          margin-bottom: 18px;
        }
        &.one {
          width: 100px;
          margin-right: 100px;
        }
        &.two {
          width: 160px;
          margin: 0 70px;
          .programsbox {
            height: 110px;
          }
        }
        &.three {
          flex: 1;
        }
      }
    }
  }
  .programsbox {
    height: 100px;
    overflow: scroll;
    scrollbar-width: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  }

  .divider {
    width: 100%;
    height: 0px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 40px;
  }
  .copyright {
    font-family: 'OPPOSans-M';
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.45);
    a {
      color: rgba(255, 255, 255, 0.45);
    }
  }
}
</style>
