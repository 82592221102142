import { render, staticRenderFns } from "./heads1.vue?vue&type=template&id=48540930&scoped=true&"
import script from "./heads1.vue?vue&type=script&lang=js&"
export * from "./heads1.vue?vue&type=script&lang=js&"
import style0 from "./heads1.vue?vue&type=style&index=0&id=48540930&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48540930",
  null
  
)

export default component.exports