<template>
  <div class="headers w">
    <div
      class="logoBox"
      @click="$router.push('/index')"
      style="cursor: pointer"
    >
      <img src="../assets/imgs/logo.png" alt="" />
      <div class="name">恒速科技</div>
    </div>

    <div class="navs">
      <div
        class="nav"
        v-for="(item, index) in navList"
        :key="item.id"
        @click="handleClick(item, index)"
        :class="activeIndex == index ? 'light' : ''"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
import { findBanner } from '../../src/api/home'
export default {
  data() {
    return {
      activeIndex: localStorage.getItem('activeIndex')
        ? localStorage.getItem('activeIndex')
        : 0,
      bannerLists: [],
      navList: [
        { id: 1, name: '首页', path: '/index' },
        { id: 2, name: '我们的产品', path: '/project' },
        { id: 3, name: '定制服务', path: '/customService' },
        { id: 4, name: '关于我们', path: '/about' },
        { id: 5, name: '人才招聘', path: '/hire' }
      ]
    }
  },
  created() {
    this._initData()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.navList.forEach((item, index) => {
          if (item.path == val.path) {
            this.activeIndex = index
          }
        })
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleScroll() {
      let scrollY = window.pageYOffset
      if (scrollY > 150) {
        this.$emit('isShowBg', true)
      } else {
        this.$emit('isShowBg', false)
      }
    },
    _initData() {
      findBanner().then((res) => {
        if (res.status === 200) {
          this.bannerLists = res.data
          console.log('acashcac', res.data)
        }
      })
    },

    handleClick(item, index) {
      this.activeIndex = index
      localStorage.setItem('activeIndex', this.activeIndex)
      this.$router.push(item.path)
    }
  }
}
</script>
<style scoped lang="less">
.headers {
  height: 88px;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  font-family: 'OPPOSans-M';

  .logoBox {
    width: 300px;
    height: 100%;

    display: flex;
    align-items: center;

    img {
      width: 30px;
      height: 30px;
      object-fit: cover;
      margin-right: 10px;
    }
    .name {
      font-size: 20px;
      font-weight: 700;
      color: #fff;
      letter-spacing: 2px;
    }
  }
  .navs {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .nav {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: #ffffff;
      opacity: 0.6;
      &:hover {
        opacity: 1;
        cursor: pointer;
      }
      &.light {
        opacity: 1;
      }
    }
  }
}
</style>
